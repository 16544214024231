import ReactDOM from 'react-dom';
import {bindable, customElement, inject, noView} from 'aurelia-framework';

import RoutingMap from './maps/routing-map';
import {GoogleApiWrapper} from "google-maps-react";
import {Client} from "../../api/client";

@noView()
@inject(Element, Client)
@customElement('sio-tourism-bus-routing-map')
export class BusRoutingMap {

    @bindable data
    reactComponent = {};
    client;
    apikey = '';
    language = '';

    constructor(element, client) {
        this.element = element;
        this.client = client;
    }

    render() {

        let markers = (items) => {
            let markers = [];
            if (items.length === 0 || items[0].leg === undefined) {
                return [];
            }
            markers.push({
                lat: items[0].leg.startLocation.lat,
                lng: items[0].leg.startLocation.lng,
                title: items[0].leg.startAddress
            })
            for (let item of items) {
                if (item.leg !== undefined) {
                    markers.push({
                        lat: item.leg.endLocation.lat,
                        lng: item.leg.endLocation.lng,
                        title: item.leg.endAddress
                    })
                }
            }
            return markers;
        }

        let journeyMarkers = (data) => {
            console.log(data, 'data');
            let markers = [];
            if (data.journeyLocations === undefined || data.journeyLocations.length === 0) return [];

            data.journeyLocations.forEach(location => {

                let title = location.title;
                let address = location.address;
                let coordinates = address.coordinates;

                title = title.concat('\n' + address.addressLine1);
                if (address.country !== undefined) {
                    title = title.concat(', ' + address.country);
                    title = title.concat(' ' + address.zip + ' ' + address.city);
                } else {
                    title = title.concat(', ' + address.zip + ' ' + address.city);
                }
                if (address.region !== undefined) {
                    title = title.concat('\n' + address.region);
                }

                markers.push({
                    lat: coordinates.lat,
                    lng: coordinates.lng,
                    title: title
                })
            })
            return markers;
        }


        let polylines = (data) => {
            if (data.calculatedDirection === undefined || data.calculatedDirection.polylines === 0) return [];

            let polylines = [];

            for (let item of data.calculatedDirection.polylines) {
                if (item.polyline !== undefined) {
                    polylines.push(item.polyline);
                }
            }

            if (polylines.length === 0) return [];

            return polylines;
        }
        console.log(this.data);

        this.reactComponent = ReactDOM.render(
            <GoogleRoutingMap data={this.data} polylines={polylines(this.data)} markers={journeyMarkers(this.data)}
                              apiKey={this.apikey} language={this.language}/>,
            this.element
        );


    }

    async bind() {

        let settings = await this.client
            .get('maps/google/apikey');

        if (settings !== undefined) {
            this.apikey = settings.apiKey ?? '';
            this.language = settings.locale ?? 'en';
        }

        this.render();


    }

    dataChanged(newVal) {
        this.bind();
    }

}

const GoogleRoutingMap = GoogleApiWrapper(
    (props) => ({
            apiKey: props.apiKey,
            language: props.language,
        }
    ))(RoutingMap)
