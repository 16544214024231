import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {I18N} from "aurelia-i18n";
import {FlashService} from "../../flash/flash-service";
import {EventAggregator} from "aurelia-event-aggregator";
import {BindingSignaler} from "aurelia-templating-resources";
import {DialogService} from "aurelia-dialog";
import {StandardActions} from "../../action/standard-actions";
import {WorkflowService} from "../../workflow/workflow-service";
import {ConfigurationLoader} from "../../table/loader/configuration-loader";
import {RendererService} from "../../table/service/renderer-service";

import "./bus-routing-manager.less";

@customElement('sio-tourism-bus-routing-manager')
@inject(Client, I18N, FlashService, EventAggregator, BindingSignaler, DialogService, StandardActions, WorkflowService, ConfigurationLoader, RendererService)
export class BusRoutingManager {
    @bindable
    journey;
    @bindable options


    viewReady = false;
    sortingInitialized = false;

    subscribers = [];

    config = {
        routeColumnsConfig: {},
        routeItemColumnsConfig: {},
        journeyLocationColumnsConfig: {},
        journeyConfig: {}
    };

    routes = [];
    locations = [];


    constructor(client, i18n, flash, ea, signaler, dialogService, standardActions, workflowService, configurationLoader, renderService) {
        this.client = client;
        this.i18n = i18n;
        this.flash = flash;
        this.ea = ea;
        this.signaler = signaler;
        this.dialogService = dialogService;
        this.actions = standardActions;
        this.workflowService = workflowService;
        this.configLoader = configurationLoader;
        this.renderService = renderService;
    }

    attached() {
        // Add event listeners
        this.eventListeners();
    }


    detached() {
        this.subscribers.forEach(subscriber => {
            subscriber.dispose();
        })
        this.viewReady = false;
    }

    bind() {
        let promises = [];

        promises.push(this.updateJourneyConfig());
        promises.push(this.updateRouteConfig());
        promises.push(this.updateRouteItemConfig());
        promises.push(this.updateJourneyLocationConfig());

        Promise.all(promises).then(() => {

            let subscriber = this.ea.subscribe('sio_form_post_submit', response => {
                this.ea.publish('sio_routing.reload.items', {config: {modelId: 'tourism/journey'}});
            });

            this.subscribers.push(subscriber)

            // populate items at first start
            this.ea.publish('sio_routing.reload.items', {config: {modelId: 'tourism/journey'}});
            this.viewReady = true;
        })

    }

    /**
     * some event listeners
     */
    eventListeners() {

        let subscribe;
        // trigger when reload items needed
        subscribe = this.ea.subscribe('sio_routing.reload.items', response => {
            if (response.config.modelId !== this.journey.modelId) {
                return;
            }
            Promise.resolve(this.updates()).then(() => {
                this.signaler.signal('locationsUpdated');
                this.signaler.signal('routesUpdated');
                this.signaler.signal('routingStateUpdate');
                this.ea.publish('sio_routing.reload.items.success', {config: {modelId: 'tourism/journey'}});
            })
        });
        this.subscribers.push(subscribe);

    }

    async updateJourneyConfig() {
        const data = await this.configLoader.get('tourism/journey', null);
        this.config.journeyConfig = await data;

    }

    async updateRouteConfig() {
        const data = await this.configLoader.get('tourism-bus/bus-routing-route', null);
        this.config.routeColumnsConfig = await data.columns;
    }

    async updateRouteItemConfig() {
        const data = await this.configLoader.get('tourism-bus/bus-routing-route-item', null);
        this.config.routeItemColumnsConfig = await data.columns;
    }

    async updateJourneyLocationConfig() {
        const data = await this.configLoader.get('tourism-bus/bus-routing-journey-location', null);
        this.config.journeyLocationColumnsConfig = await data.columns;
    }

    _getColumnConfigForJourneyField(field) {
        return this.config.journeyConfig.columns.find(config => config.property === field);
    }

    _generalActions() {

        let actions = [];

        let state = this.journey.busRoutingState;

        actions.push(
            {
                state: ['draft', 'active'],
                bulk: true,
                bulkOnly: false,
                buttonClass: "btn btn-default btn-sm",
                formId: "tourism-bus/bus-routing-routes-create-planing",
                icon: "glyphicon-road",
                label: "O",
                workflowId: "tourism-bus/bus-routing-routes-create-planing",
            },
            {
                state: ['draft', 'active'],
                bulk: true,
                bulkOnly: false,
                label: "TN",
                icon: "glyphicon-road",
                buttonClass: 'au-target btn btn-default btn-sm',
                workflowId: 'tourism-bus/bus-routing-routes-create',
            },
            {
                state: ['draft', 'active'],
                bulk: true,
                bulkOnly: false,
                label: "GE",
                icon: "glyphicon-road",
                buttonClass: 'au-target btn btn-default btn-sm',
                workflowId: 'tourism-bus/bus-routing-routes-generate',
            },
            {
                state: ['draft', 'active'],
                bulk: true,
                bulkOnly: false,
                icon: "sio-icon-plus",
                buttonClass: 'au-target btn btn-success btn-sm',
                workflowId: 'tourism-bus/bus-routing-route-add',
            }
        );


        return {
            actions: actions.filter((action) => {
                return action.state.includes(state);
            }).map((action) => {
                action.type = 'workflow';
                delete action.state;
                return action;
            }),
            id: this.journey.id,
            modelId: this.journey.modelId
        };
    }

    _routeStateActions() {
        let actions = [];

        let state = this.journey.busRoutingState;

        actions.push(
            {
                state: ['draft'],
                label: 'tourism-bus.routing.journey.state.set.active',
                buttonClass: 'btn btn-success btn-sm',
                confirm: 'tourism-bus.routing.journey.state.set.active-confirm',
                workflowId: "tourism-bus/bus-routing-route-state-active",
            },
            {
                state: ['active'],
                label: 'tourism-bus.routing.journey.state.set.draft',
                buttonClass: 'btn btn-warning',
                confirm: 'tourism-bus.routing.journey.state.set.draft-confirm',
                workflowId: 'tourism-bus/bus-routing-route-state-draft',
            },
            {
                state: ['active'],
                label: 'tourism-bus.routing.journey.state.set.close',
                icon: 'glyphicon-lock',
                confirm: 'tourism-bus.routing.journey.state.set.close-confirm',
                workflowId: 'tourism-bus/bus-routing-route-state-closed',
            },
            {
                state: ['closed'],
                label: 'tourism-bus.routing.journey.state.set.open',
                buttonClass: 'btn btn-danger',
                icon: 'glyphicon-lock',
                confirm: 'tourism-bus.routing.journey.state.set.open-confirm',
                workflowId: 'tourism-bus/bus-routing-route-state-open',
            },
        );

        return {
            actions: actions.filter((action) => {
                return action.state.includes(state);
            }).map((action) => {
                action.type = 'workflow';
               // action.buttonClass = 'btn btn-default';
                delete action.state;
                return action;
            }),
            id: this.journey.id,
            modelId: this.journey.modelId
        };
    }

    async updates() {
        this.loading = true;
        await this.updateLocations();
        await this.updateRoutes();
        this.loading = false;
    }

    async updateLocations() {
        let locationConditions = {
            includeArchived: false,
            journey: {
                $eq: {
                    id: this.journey.id,
                    modelId: this.journey.modelId
                }
            }
        };

        let response = await this.client.get('tourism-bus/bus-routing-journey-location?conditions=' + JSON.stringify(locationConditions) + '&embeds[]=busStopRoutingLocation');
        this.locations = await response.items.map(location => {
            if (!(location.participantCount > 0)) {
                location.participantCount = 0;
            }
            return location;
        });

    }

    async updateRoutes() {
        let routeConditions = {
            includeArchived: false,
            journey: {
                $eq: {
                    id: this.journey.id,
                    modelId: this.journey.modelId
                }
            }
        };

        let response = await this.client.get('tourism-bus/bus-routing-route?conditions=' + JSON.stringify(routeConditions) + '&embeds[]=vehicle');
        this.routes = await response.items;
    }

    /**
     * update items by ids
     * @param newItems
     * @param oldItems
     * @param deleteItems
     * @returns {*}
     */
    async updateItemsById(newItems, oldItems, deleteItems = false) {
        newItems.forEach(item => {
            let index = oldItems.findIndex(element => element.id === item.id)
            if (index === -1) {
                oldItems.push(item);
            } else {
                oldItems.splice(index, 1, item);
            }
        })

        if (deleteItems) {
            oldItems = oldItems.filter(item => {
                return newItems.findIndex(element => element.id === item.id) !== -1
            })
        }
        return oldItems;
    }
}
