import {bindable, inject} from 'aurelia-framework';
import {Client} from "../../../api/client";
import {EventAggregator} from "aurelia-event-aggregator";
import {BindingSignaler} from "aurelia-templating-resources";
import Sortable from "sortablejs";

import "./routes-panel.less"
import {StandardActions} from "../../../action/standard-actions";
import {AbstractPanel} from "./abstract-panel";
import moment from "moment-timezone";

@inject(Client, EventAggregator, BindingSignaler, StandardActions)
export class RoutesPanelCustomElement extends AbstractPanel {

    @bindable journey
    @bindable routes;
    @bindable config;
    @bindable locations;

    constructor(client, ea, signaler, actions) {
        super(client, ea);
        this.client = client;
        this.signaler = signaler;
        this.actions = actions;
    }

    attached() {
        this.viewReady = true;
        this.eventListener();
    }

    detached() {
        this.subscribers.forEach(subscriber => {
            subscriber.dispose();
        })
        this.viewReady = false;
        this.sortable = null;
    }


    eventListener() {

        this.subscribers.push(this.ea.subscribe('sio_routing.reload.items.success', evt => this._updateRoutesSorting()));

        this.subscribers.push(this.ea.subscribe('sio_routing.routes.item.sort.onAdd', evt => this.onAddEvent(evt)));

        this.subscribers.push(this.ea.subscribe('sio_routing.routes.item.sort.onUpdate', evt => this.onUpdateEvent(evt)));

        this.subscribers.push(this.ea.subscribe('sio_routing.routes.item.onDelete', evt => this.onDeleteEvent(evt)));
    }

    onAddEvent(evt) {

        let src = evt.from;
        let dest = evt.to;
        let item = evt.item;
        let promises = [];

        let index = evt.newIndex;

        if (this.getItemsFromRoute({id: dest.dataset.routeId}).length < 2) {
            index = 0;
        }


        let update = {
            route: {
                modelId: 'tourism-bus/bus-routing-route',
                id: dest.dataset.routeId
            },
            weight: index,
            item: {
                modelId: item.dataset.modelId,
                id: item.dataset.itemId
            }
        };

        promises.push(this.client.put('tourism/bus/routing/update-assignment', update));

        if (promises.length > 0) {
            Promise.all(promises).then(data => {
                this.ea.publish('sio_routing.reload.items', {config: {modelId: 'tourism/journey'}});
                // When actual dragged item is dropped, we remove it and handle
                // updating the array for our repeater ourselves

                // setTimeout(() => {
                //     evt.item.parentElement.removeChild(evt.item);
                // }, 0);

                // this.signaler.signal('locationsUpdated');
                return data;
            });
        }
    }

    onUpdateEvent(evt) {
        let dest = evt.to;
        let item = evt.item;
        let promises = [];

        if (evt.newIndex === evt.oldIndex) {
            return;
        }

        if (dest.dataset.routeId !== "") {

            let update = {
                route: {
                    modelId: 'tourism-bus/bus-routing-route',
                    id: dest.dataset.routeId
                },
                weight: evt.newIndex,
                item: {
                    modelId: item.dataset.modelId,
                    id: item.dataset.itemId
                }
            };

            promises.push(this.client.put('tourism/bus/routing/update-assignment', update));
        }

        if (promises.length > 0) {
            Promise.all(promises).then(data => {
                this.ea.publish('sio_routing.reload.items', {config: {modelId: 'tourism/journey'}});
                // When actual dragged item is dropped, we remove it and handle
                // updating the array for our repeater ourselves
                setTimeout(() => {
                    // evt.item.parentElement.removeChild(evt.item);
                }, 0);
                return data;
            });
        }
    }

    onDeleteEvent(evt) {
        let promises = [];
        let items = this.getItemsFromRoute({id: evt.routeId});

        let index = items.findIndex(item => {
            return item.id === evt.itemId;
        })

        // items.splice(index, 1);
        // items = this.updateWeight(items);

        let update = {
            item: {
                modelId: evt.modelId,
                id: evt.itemId
            }
        };


        promises.push(this.client.put('tourism/bus/routing/update-assignment', update));

        if (promises.length > 0) {
            Promise.all(promises).then(data => {
                this.ea.publish('sio_routing.reload.items', {config: {modelId: 'tourism/journey'}});
                return data;
            });
        }
    }

    _updateRoutesSorting() {

        this.routes.forEach((route) => {

            let routes = {
                name: 'routes',
                // put: false|
                put: ['routes', 'unassignedJobs']
            };
            this._initializeRouting('route-' + route.id, routes, true)

            let addItem = {
                name: 'addItem',
                put: ['routes', 'unassignedJobs']
            }
            this._initializeRouting('addLocation-' + route.id, addItem, true)

        })
    }

    _initializeRouting(identifier, group, sort = false) {

        this.sortable = Sortable.create(document.getElementById(identifier), {
            group: group,
            forceFallback: true,
            sort: sort,
            filter: '.no-drag',
            dataIdAttr: 'data-location-id',

            onMove: function (evt) {
                return evt.related.className.indexOf('no-drag') === -1;
            },

            onAdd: (evt) => {
                this.ea.publish('sio_routing.routes.item.sort.onAdd', evt);
            },
            onUpdate: evt => {
                this.ea.publish('sio_routing.routes.item.sort.onUpdate', evt);
            }
        });
    }

    routeRemoveItemClick(route, item) {


        this.ea.publish('sio_routing.routes.item.onDelete', {
            routeId: route.id,
            itemId: item.id,
            modelId: item.modelId
        });
    }

    toggleCalculation(item) {
        let action = {
            type: "workflow",
            workflowId: "tourism-bus/bus-routing-route-toggle-calculation",
        }

        let context = {
            id: item.id,
            modelId: item.modelId,
        };

        this.actions.getAction(action, context).action();
    }

    _getColumnConfigForItemField(field) {
        return this.config.routeItemColumnsConfig.find(config => config.property === field);
    }

    _getColumnConfigForRouteField(field) {
        return this.config.routeColumnsConfig.find(config => config.property === field);
    }

    _showRouteActions(action, route) {
        if (action.preset == 'display') {
            return true;
        }

        if (action.preset == 'delete'
            && route.items.length <= 1
            && this.routes.length > 0) {
            return true;
        }
    }


    _showRouteTime(time) {
        if (time !== undefined) {
            return time.slice(0, 2) + ':' + time.slice(2)
        }
    }

    onItemDoubleClick(item, route) {

        if (route.locked == true) {
            return;
        }
        let context = {
            id: item.id,
            modelId: item.modelId,
        };

        this.actions.getAction({
            type: 'view',
            icon: 'pencil',
            buttonClass: 'btn btn-danger btn-xs',
            viewId: 'tourism-bus/edit-bus-routing-item',
            bulk: false,
            modal: true
        }, context).action();
    }

    _getItemsByRoute(route) {
        let returnItems = [];

        let items = route.items;

        items.sort(function (a, b) {
            return a.weight - b.weight;
        })

        items.forEach((item, index) => {
            if (item.data !== undefined) {
                if (item.data.modelId == "tourism-bus/bus-routing-journey-location") {
                    item.data = this.locations.find(location => location.id === item.data.id);
                }
            }
            returnItems.push(item);

        })
        console.log('ITEMS', returnItems);
        return returnItems;
    }


    getItemsFromRoute(route) {
        let _route = this.routes.find(_route => _route.id === route.id);

        if (_route === undefined) {
            return [];
        }

        _route.items.sort(function (a, b) {
            return a.weight - b.weight;
        })

        return _route.items;
    }

    _getDate(date) {
        return moment(date).format('DD.MM.YY HH:mm');
    }


    hasRoutingLocations(route) {

        if (route.items.length > 0) {
            return !(route.items.filter(item => item.type == 'location').length > 0);
        }

        return true;
    }

}
