import {bindable, inject} from "aurelia-framework";

import "./route-item-elements.less";
import {Client} from "../../../api/client";
import {ConfigurationLoader} from "../../../table/loader/configuration-loader";
import {EventAggregator} from "aurelia-event-aggregator";
import {BindingSignaler} from "aurelia-templating-resources";

@inject(Client, ConfigurationLoader, EventAggregator, BindingSignaler)
export class RouteItemElements {
    @bindable route;
    @bindable journey;
    @bindable onlyConfirmed;

    items = [];

    subscribers = [];

    constructor(client, configLoader, ea, signaler) {
        this.client = client;
        this.configLoader = configLoader;
        this.ea = ea;
        this.signaler = signaler;
    }

    async attached() {
        this.loading = true;
        await this.fetchColumnConfig();
        await this.fetchCustomerColumnConfig();
        await this.fetchParticipantColumnConfig();
        await this.fetchItems();
        this.loading = false;
    }

    detached() {
        this.subscribers.forEach(subscriber => {
            subscriber.dispose();
        })
    }

    async bind() {
        let subscriber = this.ea.subscribe('sio_form_post_submit', async (response) => {
            await this.fetchItems();
            this.signaler.signal('fetchRouteItems');
        });
        this.subscribers.push(subscriber);
    }

    async fetchColumnConfig() {
        const data = await this.configLoader.get('tourism-bus/bus-routing-journey-location', null);
        this.columnConfig = await data.columns;
    }

    async fetchCustomerColumnConfig() {
        const data = await this.configLoader.get('customer/customer', null);
        this.customerColumnConfig = await data.columns;
    }

    async fetchParticipantColumnConfig() {
        const data = await this.configLoader.get('tourism/journey-participant', null);
        this.participantColumnConfig = await data.columns;
    }


    async fetchItems() {

        this.loading = true;
        let routeId = '';
        if (this.route) {
            routeId = this.route.id
        }

        let response = await this.client.get('tourism-bus/participant/route/location/list/' + this.journey.id + ((routeId != '') ? '?routeId=' + routeId : ''));
        this.items = await response.items;
        this.loading = false;
    }

    _getColumnConfigForField(field) {
        return this.columnConfig.find(config => config.property === field);
    }

    _getUnconfirmedParticipants(participantGroup) {

        let names = [];
        participantGroup.unconfirmedParticipants.forEach(participant => {
            names.push(participant.name);
            names.push(',<br>');
        });

        names.pop();

        return names.join('');
    }

    _getNoteContent(participantGroup) {
        let notes = [];
        participantGroup.notes.forEach(note => {

            notes.push(note.content);
            notes.push('<br><br>');
        });

        notes.pop();

        return notes.join('');
    }

    _showConfirmedOnly(participantGroup) {
        if (this.onlyConfirmed == true && participantGroup.confirmed == true) {
            return false;
        }
        return true;
    }
}
