import {bindable, inject} from 'aurelia-framework';
import {Client} from "../../../api/client";
import {EventAggregator} from "aurelia-event-aggregator";
import {BindingSignaler} from "aurelia-templating-resources";
import Sortable from "sortablejs";

import "./unassigned-locations-panel.less"
import {AbstractPanel} from "./abstract-panel";

@inject(Client, EventAggregator, BindingSignaler)
export class UnassignedLocationsPanelCustomElement extends AbstractPanel {
    @bindable journey
    @bindable locations;
    @bindable config;

    viewReady = false;
    sortingInitialized = false;

    emptyRoutesVisible = true;
    sortable;

    subscribers = [];

    locationFilter = '';

    locationFilters = [
        {
            id: '',
            title: 'Alle'
        },
        {
            id: 'location',
            title: 'Wegpunkte',
            conditions: {locationType: 'normal'}
        },
        {
            id: 'forkPoint',
            title: 'Gabelpunkte',
            conditions: {locationType: 'forkPoint'}
        },
        {
            id: 'checkPoint',
            title: 'Checkpoint',
            conditions: {locationType: 'checkPoint'}
        },
        {
            id: 'target',
            title: 'Ziele',
            conditions: {locationType: 'accommodation'}
        },
    ];

    constructor(client, ea, signaler) {
        super(client, ea);

        this.signaler = signaler;
    }


    attached() {

        this.viewReady = true;
        this.eventListener();
        this._updateUnassignedJobsSorting();

    }

    detached() {
        this.subscribers.forEach(subscriber => {
            subscriber.dispose();
        })
        this.viewReady = false;
        this.sortable = null;
    }


    _updateUnassignedJobsSorting() {
        if (!this.viewReady || this.sortingInitialized) {
            return;
        }

        let unassignedJobs = {
            name: 'unassignedJobs',
            pull: (evt) => {
                return 'clone';
            },
            put: false
        };

        this._initializeRouting('locationsNew', unassignedJobs, false);
        this.sortingInitialized = true;
    }

    _initializeRouting(identifier, group, sort = false) {

        this.sortable = Sortable.create(document.getElementById(identifier), {
            group: group,
            forceFallback: true,
            sort: sort,
            filter: '.no-drag',
            dataIdAttr: 'data-location-id',

            onMove: function (evt) {
                return evt.related.className.indexOf('no-drag') === -1;
            },

            onAdd: (evt) => {
                // this.ea.publish('sio_routing.sort.onAdd', evt);
            },
            onUpdate: evt => {
                // this.ea.publish('sio_routing.sort.onUpdate', evt);
            }
        });
    }

    hideEmptyRoutes() {
        this.emptyRoutesVisible = !this.emptyRoutesVisible;
    }

    filterButtons() {
        return this.locationFilters;
    }


    filterToggle(filter) {

        if (this.locationFilter == filter.id) {
            this.locationFilter = '';
        } else {
            this.locationFilter = filter.id;
        }
        this.signaler.signal('locationsUpdated');
    }

    isActiveFilter(filter) {
        return this.locationFilter == filter.id;
    }

    getFilteredLocations() {
        let locations = this.locations;
        if (this.locationFilter != '') {
            let filter = this.locationFilters.filter((filter) => filter.id == this.locationFilter);
            let conditions = Object.entries(filter.pop().conditions);
            return locations.filter((location) => {
                return conditions.reduce(function (acc, condition) {
                    if (acc === true) return acc;
                    if (location[condition[0]] == condition[1]) {
                        return true
                    }

                    return false;
                }, {});

            });
        }
        return locations.sort(function (a, b) {
            return a.locationType !== 'accommodation' || b.participantCount - a.participantCount;
        });

    }

    eventListener() {
    }

    _getLocationPanelClass(location) {
        let classes = super._getLocationPanelClass(location);

        // has assignment for all participants && location assigned
        if (
            location.assignedParticipantCount > 0
            && location.participantCount > 0
        ) {
            classes.push('participants-all-assigned');
        }
        return classes;
    }
}
